.main {
  font-family: "Permanent Marker", cursive;
  font-family: "Staatliches", cursive;
  background-color: #fffafa;
  width: 100vw;
  height: 100vh;
  background-size: 100% auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-container {
  position: absolute;
  text-align: center;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2em;
}

.inner-container {
  text-align: center;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 2em;
}

.drawerText {
  font-family: "Permanent Marker", cursive;
  font-family: "Staatliches", cursive;
  color: #e35858;
  font-size: 32px;
  text-align: center;
}
.menu-drawer-hr {
  width: 75%;
  border: 1px solid #b5bdc9;
  background-color: #fffafa;
}

.open-hours-box {
  width: 300px;
  height: 80px;
  position: fixed;
  bottom: 20px;
  left: 30px;
  z-index: 2;
  display: block;
  color: #e35858;
}

.open-hours-box-2 {
  width: 300px;
  height: 80px;
  position: fixed;
  bottom: 20px;
  left: 250px;
  z-index: 2;
  display: block;
  color: #e35858;
}

.opening-soon-text {
  color: #000000;
  position: absolute;
  transform: rotate(-30deg);
  top: 10%;
  left: 17%;
  background-color: #ffffff;
  padding: 3px;
}

.sun-thur {
  font-size: 2vw;
  text-align: left;
}

.telephoneNumber {
  font-size: 2vw;
}

.address-box {
  text-align: right;
  width: 400px;
  height: 80px;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 2;
  display: block;
}

.seperator {
  color: #b5bdc9;
  margin-left: 15px;
  margin-right: 15px;
}

.clean-link,
.clean-link:focus,
.clean-link:active {
  text-decoration: none;
  color: #e35858;
}

.clean-link:hover {
  color: #ffb2b2;
  cursor: pointer;
  text-decoration: none;
}

.mobile-icon {
  color: #b5bdc9;
}

#offset {
  animation: letter-flicker 2s linear infinite;
}

.bounce {
  color: #b5bdc9;
  animation: bounce 2s;
  animation-iteration-count: infinite;
}

@keyframes bounce {
  0%,
  25%,
  50%,
  75%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-12px);
  }
}

@media only screen and (max-width: 700px) {
  .center-container {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2em;
  }
  .opening-soon-text {
    color: #000000;
    position: absolute;
    transform: rotate(0deg);
    top: 5%;
    left: auto;
    text-align: center;
    background-color: #ffffff;
    padding: 3px;
  }
}

.mobile-contact-container {
  width: 100vw;
  background-color: #fffafa;
  padding: 1rem;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  color: #b5bdc9;
  flex-shrink: 1;
  overflow: hidden;
}

.nounderline {
  text-decoration: none !important;
}

@media all and (max-width: 899px) and (min-width: 701px) {
  h2 {
    font-size: 20px !important;
    font-weight: bold;
  }
}

@media all and (max-width: 899px) and (min-width: 701px) {
  h3 {
    font-size: 20px !important;
    font-weight: bold;
  }
}
